import {SSO_USER_DATA} from "../actions/ssoUserData";

export default (state = null, {type, ssoData = {}}) => {
    Object.freeze(state);
    switch (type) {
        case SSO_USER_DATA:
            return {...ssoData};
        default:
            return state;
    }
};
