import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Wrapper from "../global/Wrapper";
import Pagination from "../global/Pagination/Pagination";
import TableController from "../global/Pagination/table/Controller";
import Search from "../global/inputs/Search";
import Modal from "../global/templates/Modal";
import InviteModal from "../global/templates/InviteModal";

import allUsers from "../../util/users/allusers";
import { allUserData } from "../../actions/allUsers";
import { user as deleteRecord } from "../../util/delete/user";
import inviteOne from "../../util/users/inviteOne";
import inviteUsers from "../../util/users/inviteUsers";
import { useDataFilter } from "../global/hooks/useDataFilter";

const Users = (props) => {
	const recommendedPageLimit = 10;
	const dataLimit = 20;
	const tableHeaders = ["AVATAR", "NAME", "EMAIL", "INVITED", "PARTICIPATING",  "ACTIONS"];
	const tableFields = ["name", "username", "INVITED", "started", "action"];

	const [term, setTerm] = useState(null);
	const [userLength, setUserLength] = useState();
	const [showInviteAllModal, setShowInviteAllModal] = useState(false);
	const [showUserModal, setShowUserModal] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);
	const [emailTerm, setEmailTerm] = useState(null);

	const { control, handleSubmit, formState: { errors }, reset } = useForm({});
	const modalMessage = "Add students to the class test.";
	const modalHeader = "Invite Users";
	const searchSelectName = "mail";
	const [filteredData] = useDataFilter(
		props.users,
		term,
		"firstName LastName",
		"combined"
	);

	const deleteUser = async (email) => {
		const response = await deleteRecord(email);
		setUserLength(parseInt(response.length));
	};

	const reInviteUser = async (user) => {
		const response = await inviteOne(user, props.session);
		console.log(response)
	};

	const handleClose = (e) => {
		e.preventDefault();
		setShowUserModal(false);
	};

	const submitForm = (data) => {
		inviteUsers(data.mail, props.session);
		setShowUserModal(false);
		reset();
	};

	useEffect(() => {
		async function fetchData() {
			await allUsers().then(async (data) => {
				props.allUserData(await data);
			});
		}
		fetchData();
	}, [userLength]);

	return (
		<Wrapper headerText={'Students'}>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
						{showInviteAllModal &&
							<InviteModal
								header="Invite All Users"
								handleCloseModal={() => setShowInviteAllModal(false)}
							/>
						}

						{showUserModal &&
							<Modal
								header={modalHeader}
								handleCloseModal={handleClose}
								handleSubmit={handleSubmit}
								submitForm={submitForm}
								searchSelectName={searchSelectName}
								message={modalMessage}
								primaryText={'Save'}
							/>
						}

						<div className="page-header">
							<div>
								<h2 className="tx-24 mg-b-5">Manage the students taking your test</h2>
							</div>

							<div className="d-flex">
								<div className="justify-content-center">
									<Search
										placeholderText="Search By User"
										setParentTerm={setTerm}
									/>

									<button
										type="button"
										className="btn btn-secondary my-2 btn-icon-text mg-r-10"
										onClick={() => setShowUserModal(true)}
									>
										<FontAwesomeIcon
											className="sidemenu-icon mg-r-4"
											icon={`fa-solid fa-user-plus`}
										/>
										Add Students
									</button>

									<button
										type="button"
										className="btn btn-secondary my-2 btn-icon-text"
										onClick={() => setShowInviteAllModal(true)}
									>
										<FontAwesomeIcon
											className="sidemenu-icon mg-r-4"
											icon={`fa-solid fa-paper-plane`}
										/>
										Invite All
									</button>
								</div>
							</div>
						</div>

						<div className="row row-sm">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
								<div className="card custom-card pd-0">
									<div className="card-body pd-0">
										<Pagination
											type="person"
											data={
												filteredData && filteredData.length > 0 && term
													? filteredData
													: props.users
											}
											dataLimit={dataLimit}
											recommendedPageLimit={recommendedPageLimit}
											RenderComponent={TableController}
											deleteUser={deleteUser}
											reInviteUser={reInviteUser}
											tableHeaders={tableHeaders}
											tableFields={tableFields}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);

};

const mapStateToProps = ({ session, users }) => ({ session, users });
const mapDispatchToProps = (dispatch) => ({ allUserData: (data) => dispatch(allUserData(data)) });
export default connect(mapStateToProps, mapDispatchToProps)(Users);
