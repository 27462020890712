import AUTH_BASE_URL from '../AUTH_BASE_URL'

export function updateUser (data) {
    return fetch(`${AUTH_BASE_URL}settings/update`, {
        method: "POST",
        body: JSON.stringify({data}),
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        }
    })
}
