import { SET_STUDENT_STATS } from '../actions/studentStats';
const initialState = {
	studentStats: null
};

export const studentStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_STUDENT_STATS:
			return {
				...state,
				studentStats: action.payload,
			};
		default:
			return state;
	}
}
