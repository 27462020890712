import {REPO_LOADING} from "../actions/repoLoading";

export default (state = null, {type, repoLoading = null }) => {
	Object.freeze(state);
	switch (type) {
		case REPO_LOADING:
			return repoLoading;
		default:
			return state;
	}
};
