export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

export const receiveCurrentUser = user => ({
    type: RECEIVE_CURRENT_USER,
    user
});

export const updateSession = (user) => async dispatch => {
    // console.log('user from update session')
    return await dispatch(receiveCurrentUser(user));
};