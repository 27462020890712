import React from 'react';
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {ssoSignin} from "../../actions/session/ssoSignIn";
import {asyncLocalStorage} from "../global/msal/setSsoLocalStorage";

const SingleSignOn = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstName] = useState(location.state.firstName);
  const [lastName] = useState(location.state.lastName);
  const [email] = useState(location.state.email);

  /**
   * Check if User has pre-registered
   * If so, the sessionize.  Note: AuthRoute
   * will redirect to /dashboard route after
   * user has been sessionized during sign in.
   **/
  useEffect(() => {
    // Verify if user has pre-registered
    const retrieveUserRegistration = async () => {
      props.ssoSignin(location.state).then((response) => {
        // console.log("ssoSignInResponse:", response);
        // Check if login is sso only
        asyncLocalStorage.getItem("endeavor-sso").then((ssoLogin) => {
          // console.log("ssoLogin only:", ssoLogin);
          // If ssoLogin redirect to /sign-up page
          if (ssoLogin) {
            navigate("/sign-up", { state: response });
          }
        });
      });
    };

    // Sessionize pre-registered user with location data
    retrieveUserRegistration();
  }, []);
  return (
    <>
      <h1>Single Sign-On Page</h1>
      <div style={{ paddingLeft: "20px" }}>
        Name: {firstName + " " + lastName}
        <br />
        Email: {email}
      </div>
    </>
  );
};

// Maps the data inside our redux store, to props
const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  ssoSignin: (user) => dispatch(ssoSignin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
