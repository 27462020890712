import {FILTERED_GITLAB_REPOS} from "../actions/filteredData";

export default (state = null, {type, filteredRepos = null }) => {
	Object.freeze(state);
	switch (type) {
		case FILTERED_GITLAB_REPOS:
			return filteredRepos;
		default:
			return state;
	}
};
