import {ALL_TEST_DATA} from "../actions/allUserTests";

export default (state = null, {type, allTests = null }) => {
	Object.freeze(state);
	switch (type) {
		case ALL_TEST_DATA:
			return allTests;
		default:
			return state;
	}
};
