import {forgotPassword as forgotUtil} from '../../util/session/forgotPassword';

export const forgot = user => async dispatch => {
  const response = await forgotUtil(user);
  // if (response.ok) {
  //   // console.log(response.statusText)
  //   return response
  // } else {
  //   console.log('error')
  // }
  return response
};
