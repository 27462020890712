import {ALL_GITLAB_REPOS} from "../actions/allGitlabRepos";

export default (state = null, {type, repos = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_GITLAB_REPOS:
            return repos;
        default:
            return state;
    }
};
