import AUTH_BASE_URL from "../AUTH_BASE_URL";

const inviteOne = async (recipient, sender) => {
    const url = `usertesting/classtest/student/invite/one`
     const params = {
         recipient,
         sender
     }
 
     const response = await fetch(AUTH_BASE_URL + url, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify({data: params}),
       });
 
       // transform results from object to json
       const data = await response.json();
 
       // call search function
       return data
  };

  export default inviteOne
