import {ALL_USER_DATA} from "../actions/allUsers";

export default (state = null, {type, users = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_USER_DATA:
            return users;
        default:
            return state;
    }
};
