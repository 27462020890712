import {signup as signUpUtil} from "../../util/session/signUp";
import ssoSignUpUtil from "../../util/session/ssoSignUp";
import {asyncLocalStorage} from "../../components/global/msal/setSsoLocalStorage";

export const signup = (data) => async (dispatch) => {
  const ssoLogin = await asyncLocalStorage.getItem("endeavor-sso");
  // console.log("SignUp: ssoLogin:", ssoLogin);
  let response;

  if (ssoLogin) {
    data.isSso = ssoLogin;
    // console.log("signUp: data:", data);
    response = await ssoSignUpUtil(data);
  } else {
    // console.log("signUp: data:", data);
    response = await signUpUtil(data);
  }
  // if (response.ok) {
  //   return response
  // } else {
  //   console.log('error')
  // }
  // console.log("SignUp Return Response:", response);
  return response;
};
