import {GITLAB_RENDER_REPOS} from "../actions/gitlabRenderRepos";

export default (state = null, {type, gitlabRenderData = null }) => {
    Object.freeze(state);
    switch (type) {
        case GITLAB_RENDER_REPOS:
            return gitlabRenderData;
        default:
            return state;
    }
};
