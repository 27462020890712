import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Wrapper from '../global/Wrapper';
import { updateSession } from '../../actions/session/updateSession';
import TurnOnTest from "./TurnOnTest/TurnOnTest";

const Dashboard = ({ session }) => {
	const isAuthenticated = useIsAuthenticated();
	const { instance } = useMsal();
	const navigate = useNavigate();

	return (
		<Wrapper headerText={'Dashboard'}>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body dashboard">
						<div className="page-header">
							<div>
								<h2 className="tx-24 mg-0">Welcome {session.username}!</h2>
							</div>
						</div>
						<div className={"page-body"}>
							<div className="row row-sm">
								<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
									<TurnOnTest />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = {
	updateSession,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
