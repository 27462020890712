import {useEffect, useState} from "react";

/** Description:: Returns a list of results filtered
 * by search term.
 *
 * @param {*} data - Unfiltered data set to be searched.
 * @param {*} term - Search term
 * @param {*} fields - the field/s to search seperated by space.
 * @param {Optional} type - Type of search:  "combined".
 * Add the names of the fields to be combined,
 * seperated by a space.  Note default is single field,
 * which doesn't require a type name.
 * @returns results
 * Example Call: Users.js
 */
export const useDataFilter = (data, term, fields, type=null) => {
  // console.log("useDataFilter: Data:", data, "; term:", term, ";fields:", fields, "; TYPE:", type)
  const [results, setResults] = useState();

 function filter() {
    // Return filtered results
    return data.filter((item, index) => {
        const fieldType = typeof fields;
        const isFieldStr = fieldType === "string"

      switch(true) {
        case type === "combined":
          const combinedFields = fields.split(' ');
          let combinedValue ='';
          // console.log("UseDataFilter: CombinedFields:", combinedFields);

          // Get values for combined fields
          combinedFields.map((field, index) => {
            if(index === 0) {
              combinedValue = item[field];
            }
            else {
              // console.log("Combined Value:", combinedValue, "; Item[field]:", item[field])
              combinedValue += " "+item[field]
            }
          });
          // console.log("CombinedValue:", combinedValue);
          const isMatch = combinedValue.toLowerCase().includes(term?.toLowerCase());
          // console.log("ISMATCH:", isMatch);
          // results match return item to ne array
          return isMatch;

          case type === "email":
            const searchFields = fields.split(' ');
            // console.log("UseDataFilter: Item:", item, "; Fields:", fields, "; Item[field]:", item[searchFields[0]]);
            if (item && isFieldStr){
              const isMatch = item[searchFields[0]].toLowerCase().includes(term?.toLowerCase());
              // console.log("ISMATCH:", isMatch);
              return isMatch;
              }
              return false;

        default:
          // console.log("UseDataFilter: Item:", item)
          if (item && isFieldStr){
            const isMatch = item[fields].toLowerCase().includes(term?.toLowerCase());
            // console.log("ISMATCH:", isMatch);
            return isMatch;
            }
            return false;
      }

    });
  }

  useEffect(() => {
    const search =  () => {
      const results = filter();
      // console.log("useDataFilter: Results:", results);
      setResults(results);
      // On initial render, effect is called and cleanup function returned.
      // On change to term, cleanup function from last render is executed.
      return () => {
        // console.log("CLEANUP");
      };
    };

    // Set results for initial load
    if (term) {
      search();
    }
  }, [term]);

  return [results, filter];
};
