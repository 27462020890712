import React from 'react';
import './dev.css';
import {allUserData} from "../actions/allUsers";
import {userTest} from "../actions/userTest";
import {connect} from "react-redux";
const mongooseModels = [
	{
		id: 1,
		title: 'User',
		fields: {
			role: 'Number',
			instructor: 'Boolean',
			// Add more fields here...
		}
	}
	// Add more models here...
];
const apiEndpoints = [

];

function createInstructions(data) {
	let name = data.session.data.name
	return [
		{
			id: 1,
			title: 'Back-end setup',
			dependencies: 'Node 16.21',
			url: 'http://localhost:3100/server',
			commands: [
				`git clone git@gitlab.com:class-testing/${name}-classtest/${name}-back-end.git`,
				'yarn install',
				'yarn run dev'
			]
		},
		{
			id: 2,
			title: 'Front-end setup',
			dependencies: 'Node 16.21',
			url: 'http://localhost:3100',
			commands: [
				`git clone git@gitlab.com:class-testing/${name}-classtest/${name}-front-end.git`,
				'yarn install',
				'yarn start'
			]
		},
		{
			id: 3,
			title: 'Application setup',
			dependencies: 'Node 16.21',
			url: `http://localhost:3100/apps/${name}-app`,
			commands: [
				`git clone git@gitlab.com:class-testing/${name}-classtest/${name}-app.git`,
				'yarn install',
				'yarn start'
			]
		}
	];
}

function databaseConnections(data) {
	let name = data.session.data.name
	return [	{
		id: 1,
		databaseType: 'MongoDB',
		connectionString: `mongodb+srv://system:4X209ur5c7Y6sH3B@endeavor-a1f35d28.mongo.ondigitalocean.com/${name}?tls=true&authSource=admin&replicaSet=endeavor`
	}]
}


const DeveloperPage = (props) => {
	console.log('dev props', props)
	console.log(createInstructions(props))
	const endpointGroups = apiEndpoints.reduce((groups, endpoint) => {
		let collection = endpoint.title.trim().split(' ').pop(); // assuming the collection is the last word in the title
		collection = collection.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(); // split concatenated words
		if (!groups[collection]) {
			groups[collection] = [];
		}
		groups[collection].push(endpoint);
		return groups;
	}, {});

	return (
		<div className="developer-page">
			<h1>Developer Page</h1>

			<h4>Setting Up Your Local Environment</h4>
			<ul>
				{createInstructions(props).map((instruction) => (
					<li key={instruction.id}>
						<h2>{instruction.title}</h2>
						<p><strong>Dependencies:</strong> {instruction.dependencies}</p>
						<p><strong>Local URL:</strong> {instruction.url}</p>
						<p><strong>Commands:</strong></p>
						<pre>{instruction.commands.join('\n')}</pre>
					</li>
				))}
			</ul>

			<h4>Database Connection</h4>
			<ul>
				{databaseConnections(props).map((connection) => (
					<li key={connection.id}>
						<p><strong>Database Type:</strong> {connection.databaseType}</p>
						<p><strong>Connection String:</strong> {connection.connectionString}</p>
					</li>
				))}
			</ul>

			<h2>Mongoose Models</h2>
			{mongooseModels.map((model) => (
				<div key={model.id} className="model-section">
					<h3>{model.title}</h3>
					<table className="model-table">
						<thead>
						<tr>
							<th>Field</th>
							<th>Type</th>
						</tr>
						</thead>
						<tbody>
						{Object.entries(model.fields).map(([field, type], i) => (
							<tr key={i}>
								<td>{field}</td>
								<td>{type}</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			))}

			<h4>API Endpoints</h4>
			{
				Object.entries(endpointGroups).map(([collection, endpoints]) => (
					<div key={collection}>
						<h5>{collection}</h5>
						<ul>
							{endpoints.map((endpoint) => (
								<li key={endpoint.id}>
									<p><strong>Title:</strong> {endpoint.title}</p>
									<p><strong>URL:</strong> {endpoint.url}</p>
									<p><strong>Methods:</strong> {endpoint.methods.join(', ')}</p>
									<p><strong>Description:</strong> {endpoint.description}</p>
								</li>
							))}
						</ul>
					</div>
				))
			}
		</div>
	);
}

const mapStateToProps = ({session, users, userTestData}) => ({
	session, users, userTestData
});

const mapDispatchToProps = dispatch => ({
	allUserData: (data) => dispatch(allUserData(data)),
	userTest: (data) => dispatch(userTest(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeveloperPage);
