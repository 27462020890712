import React, { useState } from 'react';
import { connect } from 'react-redux';
import { allUserData } from '../../../actions/allUsers';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';

const InviteModal = (props) => {
	async function handleInvite(e) {
		console.log('handle invite', props.session.data.url)
		try {
			const response = await fetch(
				`${AUTH_BASE_URL}usertesting/classtest/student/invite/all`,
				{
					method: 'GET'
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			props.allUserData(data);
			props.handleCloseModal(e);
		} catch (error) {
			console.error('There was a problem with the fetch operation: ', error);
		}
	}

	return (
		<div className="main-content pt-0">
			<div className="container-fluid">
				<div className={props.modalClass ? `modal d-block ${props.modalClass}` : 'modal d-block'}
					 style={{ background: 'rgb(0 0 0 / 82%)' }}>
					<div className="modal-dialog" role="dialog">
						<div className="modal-content modal-content-demo shadow pd-t-50">
							<div className="detail-modal-body"
								 style={{
									 paddingLeft: '50px',
									 paddingRight: '50px',

								 }}>
								<h4>Confirm Invitation</h4>
								<p>By continuing, you acknowledge and understand that an email will be sent exclusively to students who have not yet received an invitation.</p>
								<p>If you would like to remind and re-invite a user, click the manage button of the user you want to remind.</p>

							</div>
								<div className="modal-footer">
									<button onClick={handleInvite} className="btn ripple btn-secondary" type="button">Invite Students</button>
									<button onClick={props.handleCloseModal} className="btn ripple btn-primary" type="button">Cancel</button>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({
	allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteModal);
