export default function (id, token) {
    return fetch(`https://gitlab.com/api/v4/projects/${id}/hooks`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => response.json()).then(data => {
            return data
    })
}