import AUTH_BASE_URL from '../AUTH_BASE_URL'

export default async function (user) {
    let data = {
        token: user.token,
        refresh: user.refresh,
        email: user.email
    }
	// console.log('validate data', data)
    return fetch(`${AUTH_BASE_URL}auth/gitlab/validate`, {
        method:'POST',
        body: JSON.stringify(data),
        headers: new Headers({
            'Authorization': `Bearer ${data.token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => {
        return response.json()
    }).then(data => {
        return data
    })

}
