import React from 'react';
import logo from '../../assets/images/brand/p-logo-white.svg'

const ThankYou = props => {

    return(
        <div className='message-page'>
            <img src={logo}/>
            <h1>Verified</h1>
            <p>This account has successfully been verified</p>
            <p>You can now close this page.</p>
        </div>
    )
}

export default ThankYou
