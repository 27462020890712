import {TOAST} from "../actions/toast";

export default (state = null, {type, toast = null }) => {
    Object.freeze(state);
    switch (type) {
        case TOAST:
            return toast;
        default:
            return state;
    }
};
