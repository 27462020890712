import AUTH_BASE_URL from '../AUTH_BASE_URL'

export const checkLoggedIn = async preloadedState => {
  console.log('FIRE check logged in', preloadedState)
  const response = await fetch(AUTH_BASE_URL + 'auth/session', {
    credentials: 'include'
  });
  const {user} = await response.json();
  console.log('FIRE user', user)
  if (user) {
    preloadedState = {
      session: user
    };
  }
  // console.log('check logged in')
  // console.log(preloadedState)
  return preloadedState;
}
