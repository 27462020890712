import {ALL_PROJECTS} from "../actions/allProjects";

export default (state = null, {type, projects = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_PROJECTS:
            return projects;
        default:
            return state;
    }
};
