import Wrapper from "./global/Wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
function results() {
	return (
		<Wrapper headerText={'Results'}>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
						<div className="page-header">
							<div>
								<h2 className="tx-24 mg-b-5">View the data associated with the test</h2>
							</div>
							<div className="d-flex">
								<div className="justify-content-center">

									<button
										type="button"
										className="btn btn-secondary my-2 btn-icon-text"
									>
										<FontAwesomeIcon
											className="sidemenu-icon mg-r-4"
											icon={`fa-solid fa-user-plus`}
										/>
										Add Question
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}
export default results;
