import {PRE_QUESTIONS} from "../actions/preQuestions";

export default (state = null, {type, preQuestionData = null }) => {
	Object.freeze(state);
	switch (type) {
		case PRE_QUESTIONS:
			return preQuestionData;
		default:
			return state;
	}
};
