import {CONNECTED_PIPELINES} from "../actions/connectedPipelineData";

export default (state = null, {type, connectedPipelines = null }) => {
    Object.freeze(state);
    switch (type) {
        case CONNECTED_PIPELINES:
            return connectedPipelines;
        default:
            return state;
    }
};
