import {ALL_ASSETS} from "../actions/allAssets";

export default (state = null, {type, assets = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_ASSETS:
            return assets;
        default:
            return state;
    }
};