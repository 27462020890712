import React, {useEffect, useRef} from 'react';

const SpinnerButton = props => {

    const submitRef = useRef(null)
    const loaderRef = useRef(null)

    useEffect(()=>{
        if(props.loading === true){
            handleLoading()
        }else{
            handleReset()
        }
    },[props.loading])

    const handleLoading = () => {
        submitRef.current.setAttribute('disabled', true)
        loaderRef.current.style.display = 'inline-block'
    }

    const handleReset = () => {
        submitRef.current.removeAttribute('disabled')
        loaderRef.current.style.display = 'none'
    }

    return(
        <button ref={submitRef} className="btn ripple btn-primary btn-block">
            <span>{props.buttonText}</span>
            <span ref={loaderRef} aria-hidden="true" className="spinner-border spinner-border-sm margin-left" role="presentation"></span>
        </button>
    )
}


export default SpinnerButton
