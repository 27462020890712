import Wrapper from "./global/Wrapper";
import Search from "./global/inputs/Search";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
function questions() {
	return (
		<Wrapper headerText={'Test Questions'}>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
			<div className="page-header">
				<div>
					<h1 className="tx-24 mg-0">Add questions for students to answer after the test</h1>
				</div>
				<div className="d-flex">
					<div className="justify-content-center">

						<button
							type="button"
							className="btn btn-secondary my-2 btn-icon-text"
						>
							<FontAwesomeIcon
								className="sidemenu-icon mg-r-4"
								icon={`fa-solid fa-user-plus`}
							/>
							Add Question
						</button>
					</div>
				</div>
			</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}
export default questions;
