import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const signin = user => (
  fetch(AUTH_BASE_URL + "auth/session", {
    method: "POST",
    body: JSON.stringify({data:user}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
);

export const checkUserExists = user => (
	fetch(AUTH_BASE_URL + "auth/session/exists", {
		method: "POST",
		body: JSON.stringify({data:user}),
		credentials: 'include',
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const resetCredentials = user => (
	fetch(AUTH_BASE_URL + "auth/session/credentials", {
		method: "POST",
		body: JSON.stringify({data:user}),
		credentials: 'include',
		headers: {
			"Content-Type": "application/json"
		}
	}).then(response => response.json())
);
