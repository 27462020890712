export default function (id, token) {
    // console.log(id)
    let url = 'https://dev.pearsonct.design/server/api/webhooks/gitlab'
    return fetch(`https://gitlab.com/api/v4/projects/${id}/hooks`, {
        method: 'POST',
        body: JSON.stringify({
            url: encodeURI(url),
            job_events: true,
            pipeline_events: true,
            push_events: true
        }),
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => {
        return response.json()
    })
}
