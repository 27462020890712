import {GITLAB_SUB_GROUPS} from "../actions/gitlabSubGroups";

export default (state = null, {type, gitlabSubGroups = null }) => {
    // console.log(gitlabSubGroups)
    Object.freeze(state);
    switch (type) {
        case GITLAB_SUB_GROUPS:
            return gitlabSubGroups;
        default:
            return state;
    }
};
