import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const checkUserExists = user => (
	fetch(AUTH_BASE_URL + "auth/session/exists", {
		method: "POST",
		body: JSON.stringify({data:user}),
		credentials: 'include',
		headers: {
			"Content-Type": "application/json"
		}
	}).then(response => response.json())
)


