import React from "react";
import {Navigate, Route} from "react-router-dom";

export const AuthRoute = (path, Component, loggedIn, data, session) => {
	console.log('the stats', session)
	if (data) {
		let url = `/apps/beck-helmus-module-four`
		sessionStorage.setItem('user', JSON.stringify(session));
		if (session.role === 1 && session.instructor === true) {
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/dashboard" /> : <Component />}
				/>
			);
		} else if (session.role === 1 && session.instructor === false){
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/developer" /> : <Component />}
				/>
			);
		}else  {
			if (session.data && session.data.live) {
				return (
					<Route
						exact
						path={path}
						element={loggedIn ? window.location.href = url : <Component />}
					/>
				)
			} else {
				return (
					<Route
						exact
						path={path}
						element={loggedIn ? <Navigate to="/not-started" /> : <Component />}
					/>
				);
			}
		}
	} else {
		return (
			<Route
				exact
				path={path}
				element={loggedIn ? <Component /> :<Navigate to="/sign-out" /> }
			/>
			)

	}
};
