import React from 'react';
import logo from '../../assets/images/brand/p-logo-white.svg'

const ThankYou = props => {

    return(
        <div className='message-page'>
            <img src={logo}/>
            <h1>Thank you</h1>
            <p>Thank you for verifying your email address. We're reviewing your account.</p>
            <p>You'll get an email about your account status shortly, usually within 24 hours.</p>
        </div>
    )
}

export default ThankYou
