import {NOT_CONNECTED_PIPELINES} from "../actions/notConnectedPipelineData";

export default (state = null, {type, notConnectedPipelines = null }) => {
    Object.freeze(state);
    switch (type) {
        case NOT_CONNECTED_PIPELINES:
            return notConnectedPipelines;
        default:
            return state;
    }
};
