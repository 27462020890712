// Check if user is registered in Endeavor and setup the user session:
import {signin as signInUtil} from "../../util/session/ssoSignIn";
import {setSsoLocalStorage} from "../../components/global/msal/setSsoLocalStorage";

export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";

export const receiveCurrentUser = (data) => {
  return {
    type: RECEIVE_CURRENT_USER,
    user: { ...data },
  };
};

// Call ssoSignIn to check if user is registered, then set payLoad in redux
export const ssoSignin = (user) => async (dispatch) => {
  // console.log("In SSO Login: useremail:", user);
  let endeavorSso;
  // If user is found then session user
  if (user.email) {
    // Sessionize User
    const response = await signInUtil(user.email);
    // console.log("ssoSignin: Response:", response);
    if (response.ok) {
      // sso login is false, resume application login
      endeavorSso = await setSsoLocalStorage("endeavor-sso", false);
      // console.log("ssoSignIn Action: endeavorSso", endeavorSso);
      const userData = await response.json();
      // set CurrentUser to User reponse
      return await dispatch(receiveCurrentUser(userData));
    }
    // Not a native user (not found in Endeavor), redirect user to SignUp Page for SSO
    else {
      // console.log("ssoSignIn: Error in Response:", response);
      // If sso login is true begin redirect
      endeavorSso = await setSsoLocalStorage("endeavor-sso", true);
      // console.log("ssoSignIn Action: endeavorSso", endeavorSso, "; user", user);
      return { user };
    }
  }

};
