import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {connectToGitLab} from "../../util/settings/connectToGitLab";
import {signin} from "../../actions/session/signIn";

import AUTH_BASE_URL from '../../util/AUTH_BASE_URL'
import {getCookie} from 'react-use-cookie';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GroupSelect from "./GroupSelect";
import SubGroupSelect from "./SubGroupSelect";

function Services (props) {
    useEffect(()=> {
        let data = {
           content: encodeURI(getCookie('glp')).toString(),
           email: props.session.email,
           token:  getCookie('token'),
           refresh: getCookie('refresh')
        }

        if (getCookie('glp')) {
            fetch(`${AUTH_BASE_URL}auth/gitlab`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                return response.json()
            }).then(data => {
                props.signin(data)
            })
        }
    },[])



    function handleOnClick () {
        connectToGitLab(props.session.email)

    }
    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Gitlab Development</label>
                        <span className="d-block tx-13 mb-0">You'll need to connect your GitLab account to use development operations</span>
                    </div>
                    <div className="card-item-body d-flex">
                        <div className="card-item-stat">
                            <div className="btn-list">
                                <button onClick={handleOnClick} className="btn ripple btn-secondary" disabled={props.session.token}>
                                    <FontAwesomeIcon icon={`fa-brands fa-gitlab`} className={'mg-r-8'}/>Connect to Gitlab
                                </button>
                            </div>
                            {
                                !props.session.token ?
                                    <span className="label text-danger d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-danger mr-1"></span>
                                        <span className={'text-danger'} style={{fontSize: '12px'}}>Not Connected</span>
                                    </span> :
                                    <span className="label text-success d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-success mr-1"></span>
                                        <span className={'text-success'} style={{fontSize: '12px'}}>Connected</span>
                                    </span>
                            }
                        </div>

                    </div>


					{
                        props.session.token ?
                            <>
                                <div className="card-item-body d-flex mg-t-40 bd-t">
                                    <div className="card-item-stat d-flex flex-column ">
                                        <div className="d-flex flex-column profile wd-100p">
                                            <span className="d-block tx-13 mg-b-10">Select a default group.</span>
                                            <div>

                                                <GroupSelect />

                                            </div>

                                        </div>
                                        <div className="d-flex flex-column profile wd-100p">
                                            <span className="d-block tx-13 mg-b-10">Select a default subgroup. </span>
                                            <div>
                                                <SubGroupSelect />
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </>

                        : <></>
                    }

                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Services);
