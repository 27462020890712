import AUTH_BASE_URL from '../AUTH_BASE_URL'

export default function getAllRepos (token, group) {
   return fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/all?group=${group}&token=${token}`, {
        method: 'GET',
    }).then(response => {
        return response.json()
    }).then(data => {
        return data.repoData;
    }).then(repos => {
		return repos
   })
}

