import React from "react";
import {Navigate, Route} from "react-router-dom";

export const ProtectedRoute = ( path, Component, loggedIn ) => {
    console.log('LOGGED IN?', loggedIn)
    return (
    <Route
        exact
        path={path}
        element={ loggedIn ? <Component/> : <Navigate to='/sign-in'/>}
      />
    )
}


