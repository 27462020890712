import React, {useRef} from "react";
import Avatar from "../global/Avatar";
import {uploadPhoto} from '../../util/settings/uploadPhoto'
import {updateUser} from "../../util/settings/updateUser";
import {signin} from "../../actions/session/signIn";
import {connect} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function ProfilePicture (props) {

    const fileUploadRef = useRef();

    function uploadFile () {
        fileUploadRef.current.click();
    }

    function onChange () {
        let photo = fileUploadRef.current.files[0]
        let formData = new FormData();
        formData.append('photo', photo)
        uploadPhoto(formData).then(response => {
            return response.json()
        }).then(data => {
			console.log('upload data', data)
            let userData = {
                avatar: data.url,
                email: props.session.email,
				id: props.session.userId
            }
            updateUser(userData).then(response => {
                return response.json()
            }).then(data =>{
                props.signin(data.user)
            })
        })
    }


    return (
     <div className="card custom-card">
         <div className="card-body">
             <div className="card-item">
                 <div className="card-item-title mb-2">
                     <label className="main-content-label tx-14 font-weight-bold mb-1">Add your profile picture.</label>
                     <span className="d-block tx-13 mb-0">Upload a photo, if you choose not to upload a photo well use your Gitlab photo or initials</span>
                 </div>
                 <div className="card-item-body d-flex justify-content-center">
                     <div className="media picture">
                         <div className="media-body">
                             <div className={'d-flex justify-content-center align-items-center'}>
                                 <Avatar user={props.session} size="xxl"/>
                                 <div className="btn-list mg-t-10 mg-20">
                                     <input ref={fileUploadRef} id="file-upload" type="file" onChange={onChange}/>
                                     <button onClick={uploadFile} className="btn ripple btn-secondary">
                                            <FontAwesomeIcon icon="fa-solid fa-upload" />
                                            <span>Upload Photo</span>
                                        </button>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
 )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePicture);
