import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import React, {useEffect, useRef, useState} from "react";
import Avatar from "./Avatar";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {signout} from "../../actions/session/signOut";
import {myNotifications} from "../../actions/myNotifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import gitlabAvatar from "../../assets/images/gitlab-avatar.png";
import digitalAvatar from "../../assets/images/digitalocean.png";
import {closeWebSocket} from "../../util/websockets/closeWebSocket";
import {saveWSClient} from '../../actions/wsclient'
import logo from '../../assets/images/framework/storybook.png'
// MSAL Logout
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import Toast from "./Toast";

const Header = (props) => {
  // MSAL: Determine is user is currently signed in
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
	const [showToastMessage, setShowToast] = useState(null)

  const navigate = useNavigate();
  const notificationsRef = useRef(null);
  const messagesRef = useRef(null);
  const profileRef = useRef(null);
  // const teamsUrl =
  //   "https://teams.microsoft.com/l/team/19%3aYSuc4aIpjlHcsZ9QjWSiyGwpZi7c6B8QHkDQYv2pdTY1%40thread.tacv2/conversations?groupId=6b413c92-d5b0-43c3-b6ac-70f19b285fb0&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b";

  const handleNotificationsMenu = () => {
    if (notificationsRef.current.classList.contains("show")) {
      notificationsRef.current.classList.remove("show");
    } else {
      notificationsRef.current.classList.add("show");
      if (profileRef.current.classList.contains("show")) {
        profileRef.current.classList.remove("show");
      }
    }
  };

  const handleProfileMenu = () => {
    if (profileRef.current.classList.contains("show")) {
      profileRef.current.classList.remove("show");
    } else {
      profileRef.current.classList.add("show");
      if (notificationsRef.current.classList.contains("show")) {
        notificationsRef.current.classList.remove("show");
      }
    }
  };
  function removeNotification(event, id) {

	  // if (event.currentTarget === '')
	  if (event.target.tagName !== 'BUTTON') {
		  // console.log('notification id', id)
		  fetch(`${AUTH_BASE_URL}notifications`, {
			  method: "DELETE",
			  body: JSON.stringify({ data: id }),
			  headers: {
				  "Content-Type": "application/json",
			  },
		  }).then((response) => {
			  fetch(`${AUTH_BASE_URL}notifications?id=${props.session.userId}`, {
				  method: "GET",
			  })
				  .then((response) => response.json())
				  .then((notifications) => {
					  // console.log('my notificaitons', notifications)
					  props.myNotifications(notifications.data);
				  });
		  });
	  }

  }
	const handleCloseToast = () => {
		setShowToast(null)
	}
  function avatarSwitcher(from) {
    if (from === "gitlab") {
      return gitlabAvatar;
    } else if (from === "digital") {
      return digitalAvatar
    }
  }
  function handleRequestHelp(event, data) {
	  event.target.disabled = true
	  fetch(`${AUTH_BASE_URL}dev-ops/help`, {
		  method: 'POST',
		  body: JSON.stringify(data.data),
		  headers: new Headers({
			  'Content-Type': 'application/json'
		  })
	  }).then(response => {
		  setShowToast('Help is on the way.  You will receive an email / notification when the issue is fixed.')
		  setTimeout(() => {
			  setShowToast(null)
		  }, 10000)
	  })
  }
 async function handleSignout(event) {
    event.preventDefault();
    // console.log("Header: In handleSignout")
    // MSAL Logout
    if (isAuthenticated) {
    // The account's ID Token must contain the login_hint optional claim to avoid the account picker
    // console.log("In Logout Redirect: account:", accounts[0]);
    instance.logoutPopup({ account: accounts[0]});
    }
    props.signout();
    closeWebSocket().then(response=>response.json()).then(()=>{
      props.wsClient.close()
      props.saveWSClient(null)
    })

  }
	console.log('header session', props.session)
	function handleClick () {

		window.location.href = `/apps/beck-helmus-module-four`
	}
	function handleSignOut () {
		window.location.href = `/sign-out`
	}
  return (
	  <>
		  {
			  showToastMessage != null ? <Toast closeToast={handleCloseToast} message={showToastMessage}/>
				  : null
		  }
		  <div
			  className={
				  props.noSidebar
					  ? "main-header sticky sticky-pin"
					  : "main-header side-header sticky sticky-pin"
			  }
		  >

			  <div className="container-fluid">
				  <div className="main-header-left">

						  <h3 style={{margin: '0', marginRight: '25px'}}>{props.headerText}</h3>

				  </div>
				  <div className="main-header-right">

					  <button onClick={handleClick} className="btn btn-primary mg-r-10">
						  <FontAwesomeIcon icon="fa-solid fa-globe mg-r-5" />
						  <span className={"mg-l-5"}>Launch Test</span>
					  </button>
					  <button onClick={handleSignOut} className="btn btn-secondary mg-r-10">
						  <FontAwesomeIcon icon="fa-solid fa-lock mg-r-5" />
						  <span className={"mg-l-5"}>Sign Out</span>
					  </button>
				  </div>
			  </div>
		  </div>
	  </>

  );
};

const mapStateToProps = ({ session, notifications, wsClient }) => ({
  session,
  notifications,
  wsClient
});

const mapDispatchToProps = (dispatch) => ({
  signout: () => dispatch(signout()),
  myNotifications: (data) => dispatch(myNotifications(data)),
  saveWSClient: (data) => dispatch(saveWSClient(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
