import ProfileItem from "./ProfileItem";
import {signin} from "../../actions/session/signIn";
import {connect} from "react-redux";
import roleData from "../../assets/data/teams_roles.json";
import React from "react";

function ProfileInfo (props) {
    const handleGetRoles = () => {
        let roles = []
        roleData.teams.forEach(team =>{
            if (props.session.team === team.name) {
             team.roles.forEach(role => {
                 roles.push({name: role})
             })
            }
        })
        return roles
    }
    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Profile Information</label>
                        <span className="d-block tx-13 mb-0">Make sure this looks right,</span>
                    </div>
                    <div className="card-item-body">
                        <div className="media-list wd-100p">
                            <div className="media">
                                <div className="media-body">
                                    <ProfileItem type={'input'} label={"First Name"} value={props.session.firstName} model={'firstName'}/>
                                    <ProfileItem type={'input'} label={'Last Name'} value={props.session.lastName} model={'lastName'}/>
                                    <ProfileItem data={roleData.teams} type={'select'} label={'Team'} value={props.session.team} model={'team'}/>
                                    <ProfileItem data={handleGetRoles()} type={'select'} label={'Role'} value={props.session.role} model={'role'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileInfo);
