import {useEffect, useState} from "react";

/** Set a timer to delay requests until user has finished typeing
 * Requirements:
 * term: the variable being monitored for a change in value
 * timeout: how long to wait for a user to stop typing to process request
 **/
export const useDebounce = (term, timeout) => {
const [ debouncedValue, setDebouncedValue] = useState(term);

useEffect(()=>{
    // set timeout, action is not executed until
    // typing stops for 500 ms
    const timeoutId = setTimeout(() => {
        term? setDebouncedValue(term): setDebouncedValue('');
    }, timeout);

    // cancel timeout
    return () => {
        // console.log("clearing the timeout");
        clearTimeout(timeoutId)
    }
});
return [debouncedValue];
}
