import {signout as signOutUtil} from '../../util/session/signOut';

export const SIGNOUT_CURRENT_USER = 'SIGNOUT_CURRENT_USER';

const signoutCurrentUser = () => ({
  type: SIGNOUT_CURRENT_USER
});

export const signout = () => async dispatch => {
  // console.log('signing out')
  const response = await signOutUtil();
  await response;
  if (response.ok) {
    dispatch(signoutCurrentUser());
    // Redirect to another page
    window.location.href = '/sign-in';
  } else {
    console.log('error');
  }
};

