import React, {useState, useEffect} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {useNavigate} from "react-router-dom";

import {setStudentStats} from "../../../actions/studentStats";
import {connect} from "react-redux";
function TurnOnTest (props) {
	const navigate = useNavigate();
	const [participating, setParticipating] = useState(25)
	const [invited, setInvited] = useState(100)
	const [total, setTotal] = useState(100)
	useEffect(()=>{
		if (props.studentStats.studentStats) {
			setParticipating(props.studentStats.studentStats.participating)
			setInvited(props.studentStats.studentStats.invited)
			setTotal(props.studentStats.studentStats.total)
		}
	}, [props.studentStats])


	useEffect(()=>{
		fetch(`${AUTH_BASE_URL}usertesting/classtest/student`).then(response => response.json()).then(data => {
			console.log(data)
			props.setStudentStats(data)
			setParticipating(data.participating)
			setInvited(data.invited)
			setTotal(data.total)
		})
	},[])

	function isLive () {

	}

	function setInvitedColor () {
		let isParticipating = Math.round(invited / total * 100)
		if (isParticipating !== 100){
			return 'text-danger mg-r-5'
		} else {
			return 'text-info mg-r-5'
		}
	}

	function setParticipationColor () {
		let isParticipating = Math.round(participating / total * 100)
		if (isParticipating <= 50){
			return 'text-danger mg-r-5'
		}  else if (isParticipating > 50 && isParticipating <= 65){
			return 'text-warning mg-r-5'
		}  else if (isParticipating > 65 && isParticipating <= 90){
			return 'text-success mg-r-5'
		} else {
			return 'text-info mg-r-5'
		}
	}
	async function liveToggle(event) {
		console.log(event.target.checked);
		fetch(`${AUTH_BASE_URL}usertesting/classtest/start`, {
			method: 'PUT',
			body: JSON.stringify({ on: event.target.checked }),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				console.log('PUT DATA');
				// Handle the response data here
			})
			.catch(error => {
				// Handle any errors that occurred during the request
			});
	}


	return (
		<div className="card custom-card pd-25">
			<div className="card-body pd-0">
				<div className="card-item">
					<div className="card-item-title mb-2">
						<label className="main-content-label tx-18 font-weight-bold mb-1" style={{textTransform: 'uppercase'}}>Starting the test</label>
						<span className="d-block tx-12 mb-0 text-muted">Toggle this on, to allow students access to the test. Until then, they will be directed to: "Test will begin soon" page.</span>
					</div>
					{
						total ?
							<>
							<div className="card-item-body">
								<div className="card-item-stat">
									<h4 className="font-weight-bold tx-13 mg-0 pd-0" style={{margin: '0', marginTop: '12px', marginBottom: '12px'}}>{participating} / {total} Students Ready</h4>
								</div>
							</div>
									<div className={"card-item-footer d-flex justify-content-between"}>
							<div className={"d-block"}>
								<div className={"d-block"}><small><b className={setParticipationColor()}>{Math.round(participating / total * 100)}%</b>Participating</small></div>
								<div className={"d-block"}><small><b className={setInvitedColor()}>{invited / total * 100}%</b>Invited</small></div>
							</div>
							<label className="custom-switch" style={{width: '25%'}}>
								<span className="custom-switch-description mg-r-10">Turn on:</span>
								<input onChange={liveToggle} type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" defaultChecked={props.session.data.live} />
								<span className="custom-switch-indicator"></span>

							</label>
									</div> </>: <button onClick={()=>{navigate('/users')}} className={"btn btn-secondary mg-t-10"}>Manage Students</button>
					}

				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({session, studentStats}) => ({
	session, studentStats
});

const mapDispatchToProps = dispatch => ({
	setStudentStats: (data) => dispatch(setStudentStats(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TurnOnTest);
