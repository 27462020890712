import {SIDEBAR_DATA} from "../actions/sidebar";

export default (state = null, {type, sidebarData = {}}) => {
	Object.freeze(state);
	switch (type) {
		case SIDEBAR_DATA:
			return sidebarData;
		default:
			return state;
	}
};
