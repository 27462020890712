import {FILTER_BY} from "../actions/filterBy";

export default (state = null, {type, filterBy = null }) => {
	Object.freeze(state);
	switch (type) {
		case FILTER_BY:
			return filterBy;
		default:
			return state;
	}
};
