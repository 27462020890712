import React from 'react';
import {connect} from "react-redux";

function Avatar(props) {

    if (props.role !== 1 && props.user && props.user.firstName) {
		const firstNameInitial = props.user.firstName.charAt(0);
		const lastNameInitial = props.user.lastName.charAt(0);
        if (props.user.avatar === null || props.user.avatar === ' ') {
            return (
                <div className={`avatar avatar-${props.size} t`}>
					{firstNameInitial.toUpperCase()}
				{lastNameInitial.toUpperCase()}
                </div>
            )
        } else {
            return (
                <div className={`avatar avatar-${props.size}`}>
                    <img alt="avatar" className="rounded-circle" src={props.user.avatar} />
                </div>
            )
        }
    } else {
		return null
	}
    // else {
    //     if (props.session.avatar === null || props.session.avatar === ' ' && props.session.gitLab === null) {
    //         return (
    //             <div className={`avatar avatar-${props.size}`}>
    //                 {props.session.firstName.charAt(0).toUpperCase()}{props.session.lastName.charAt(0).toUpperCase()}
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <div className={`avatar avatar-${props.size}`}>
    //                 <img alt="avatar" className="rounded-circle" src={props.session.avatar} />
    //             </div>
    //         )
    //     }
    // }

}

const mapStateToProps = ({session}) => ({
    session
});

export default connect(
    mapStateToProps,
)(Avatar);
