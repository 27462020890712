import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Wrapper from './global/Wrapper';
import startTest from "../util/usertesting/startTest";

const NotStarted = ({ session, users }) => {
	const navigate = useNavigate();

	console.log(session.data);

	const handleClick = () => {
		startTest({ session, users });
	}

	async function handleBackClick() {
		if (session.data.collectPersonal) {
			navigate("/information")
		} else {
			navigate("/welcome")
		}

	}
	return(

			<div style={{display: 'flex', maxWidth: '1280px', margin: '0 auto', flexDirection:'column', padding: '40px', textAlign: 'center'}}>
				<h1>Thank you for your participation!</h1>
				<p>The test will begin shortly.  You will get an email from the instructor, when the test begins.</p>
			</div>

	)
}

const mapStateToProps = ({ session, users }) => ({
	session,
	users
});

export default connect(
	mapStateToProps
)(NotStarted);
