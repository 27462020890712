import {asyncLocalStorage} from "../../components/global/msal/setSsoLocalStorage";
import AUTH_BASE_URL from "../AUTH_BASE_URL";


export const signout = () => {
  // clearPearsonCredentials on Signout:

  return fetch(AUTH_BASE_URL + "auth/session", { method: "DELETE" })
}

const clearPearsonCredentials = async() => {
  // set endeavor-sso  localStorage variable to false
  // console.log("signOut: endeavor-sso B4 signout:",  await asyncLocalStorage.getItem("endeavor-sso"));
  const ssoLogin = await asyncLocalStorage.setItem("endeavor-sso", false)
  // console.log("signOut: endeavor-sso after signout:",  ssoLogin);
}
