import {MY_NOTIFICATIONS} from "../actions/myNotifications";

export default (state = null, {type, myNotifications = null }) => {

    Object.freeze(state);
    switch (type) {
        case MY_NOTIFICATIONS:
            return myNotifications;
        default:
            return state;
    }
};
