import React, {useEffect} from "react";
import {signout} from "../../actions/session/signOut";
import {connect} from "react-redux";

const SignOut = (props) => {


  useEffect(() => {
	props.signout()
      window.location.href = '/sign-in'
  },[])

  return (
    <div className="auth-page sign-in">
	</div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({
	signout: () => dispatch(signout())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
