import React, {useEffect, useState} from 'react'
import {updateUser} from "../../util/settings/updateUser";
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";
import {updateSession} from "../../actions/session/updateSession";

function ThemePrefs (props) {

    const [theme, setTheme] = useState('default')

    useEffect(()=>{
        if(props.session.theme){
            setTheme(props.session.theme)
        }
    },[])

    function handleRadio (event) {
        let value = event.target.value
        setTheme(value)
        updateUser({
            theme:value,
            email: props.session.email
        }).then(response => {
            return response.json()
        }).then(data =>{
            let newSession = {...props.session};
            newSession.theme = data.user.theme
            props.updateSession(newSession)
        })
    }


    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Theme Preferences</label>
                        <span className="d-block tx-13 mb-0">Choose a theme</span>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat">
                            <label htmlFor="default" className='rdiobox'>
                                <input type="radio" id="default" name="theme" value="default" checked={theme === 'default'} onChange={handleRadio}/>
                                <span>Default</span>
                            </label>
                        </div>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat">
                            <label htmlFor="light" className='rdiobox'>
                                <input type="radio" id="light" name="theme" value="light" checked={theme === 'light'} onChange={handleRadio}/>
                                <span>Light</span>
                            </label>
                        </div>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat">
                            <label htmlFor="purple" className='rdiobox'>
                                <input type="radio" id="purple" name="theme" value="purple" checked={theme === 'purple'} onChange={handleRadio}/>
                                <span>Purple Dark</span>
                            </label>
                        </div>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat">
                            <label htmlFor="bright" className='rdiobox'>
                                <input type="radio" id="bright" name="theme" value="bright" checked={theme === 'bright'} onChange={handleRadio}/>
                                <span>Bright</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    updateSession: (user) => dispatch(updateSession(user)),
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemePrefs);
