import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const forgotPassword = user => (
  fetch(AUTH_BASE_URL + "auth/forgot", {
    method: "POST",
    body: JSON.stringify({data:user}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
);
