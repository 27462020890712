import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Details from './Developer';
import {Navigate, Route, Routes} from 'react-router-dom';
import {ProtectedRoute} from '../util/routes/protected';
import {AuthRoute} from '../util/routes/auth';
import SignIn from '../components/authentication/SignIn';
import SignUp from '../components/authentication/SignUp';
import SingleSignOn from './authentication/SingleSignOn';
import ResetPassword from './authentication/ResetPassword';
import ForgotPassword from './authentication/ForgotPassword';
import Dashboard from './dashboard/Dashboard';
import ThankYou from './message_pages/ThankYou';
import Verified from './message_pages/Verified';
import Unauthorized from './message_pages/Unauthorized';
import Questions from './Questions';
import {saveWSClient} from '../actions/wsclient';
import {userTest} from '../actions/userTest';
import {library} from '@fortawesome/fontawesome-svg-core';
import SignOut from './authentication/SignOut';
import {
	faBars,
	faBell,
	faCheck,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faCircleExclamation,
	faCircleInfo,
	faClipboardList,
	faCodeCommit,
	faCodeCompare,
	faCodeFork,
	faDiagramNext,
	faEye,
	faEyeSlash,
	faFilter,
	faFolder,
	faGlobe,
	faHouse,
	faLock,
	faLockOpen,
	faMessage,
	faMicroscope,
	faPaperPlane,
	faPencil,
	faPlus,
	faServer,
	faTrash,
	faUpload,
	faUserPlus,
	faUsers,
	faWindowMaximize,
	faXmark
} from '@fortawesome/free-solid-svg-icons';
import Settings from "./settings/Settings";
import Users from "./users/Users";
import Results from "./Results";
import NotStarted from "./NotStarted";

library.add(
	faBars,
	faBell,
	faMessage,
	faHouse,
	faUpload,
	faUsers,
	faTrash,
	faFilter,
	faUserPlus,
	faWindowMaximize,
	faPlus,
	faLock,
	faLockOpen,
	faCodeCompare,
	faCodeCommit,
	faXmark,
	faCircleCheck,
	faCheck,
	faCircleInfo,
	faCircleExclamation,
	faCodeFork,
	faFolder,
	faChevronDown,
	faChevronUp,
	faPencil,
	faClipboardList,
	faServer,
	faMicroscope,
	faEye,
	faEyeSlash,
	faDiagramNext,
	faGlobe,
	faPaperPlane
);

function redirectRoute() {
	let redirectPath = localStorage.getItem('redirect');
}

function App(props) {
	useEffect(() => {
		console.log('the session', props.session);
		let storeData = {};
		if (props.session.data) {
			if (props.session.data.collectPersonal) {
				storeData.information = [];
			}
			if (props.session.data.preTest) {
				storeData.questions = [];
			}
			if (props.session.data.postTest) {
				storeData.survey = [];
			}
			props.userTest(storeData);
		}
	}, [props.session]);

	return (
		<div className={`app theme-light`}>
			<Routes>
				{/* Auth routes */}
				{AuthRoute('/sign-in', SignIn, props.session.userId, props.session.data ? props.session.data : 'logtest', props.session)}
				{AuthRoute('/sign-up', SignUp, props.session.userId)}
				{AuthRoute('/sso', SingleSignOn, props.session.userId)}
				{AuthRoute('/reset-password', ResetPassword, props.session.userId)}
				{AuthRoute('/forgot-password', ForgotPassword, props.session.userId)}
				{AuthRoute('/details', Details, props.session.userId)}
				<Route exact path="/" element={<Navigate to="/sign-in" />} />
				{/* Confirmation pages */}
				<Route exact path="/apps/beck-helmus-module-four" element={<Navigate to="/apps/beck-helmus-module-four" />} />
				<Route exact path="/thank-you" element={<ThankYou />} />
				<Route exact path="/unauthorized" element={<Unauthorized />} />
				<Route exact path="/verified" element={<Verified />} />

				{ProtectedRoute('/sign-out', SignOut, props.session.userId)}
				{/* Main */}

				{ProtectedRoute('/dashboard', Dashboard, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/settings', Settings, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/users', Users, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/questions', Questions, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/results', Results, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/not-started', NotStarted, props.session.userId, props.session.data, props.session)}
				{ProtectedRoute('/developer', Details, props.session.userId, props.session.data, props.session)}
			</Routes>
		</div>
	);
}

const mapStateToProps = ({ session, wsClient, userTestData }) => ({
	session,
	wsClient,
	userTestData
});

const mapDispatchToProps = (dispatch) => ({
	saveWSClient: (data) => dispatch(saveWSClient(data)),
	userTest: (data) => dispatch(userTest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
