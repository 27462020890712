import {CREATORS} from "../actions/creators";

export default (state = null, {type, creators = null }) => {
    Object.freeze(state);
    switch (type) {
        case CREATORS:
            return creators;
        default:
            return state;
    }
};
