import {WSCLIENT} from "../actions/wsclient";

export default (state = null, {type, wsClient = null }) => {
    Object.freeze(state);
    switch (type) {
        case WSCLIENT:
            return wsClient;
        default:
            return state;
    }
};
