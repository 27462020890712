import {GITLAB_GROUPS} from "../actions/gitlabGroups";

export default (state = null, {type, gitlabGroups = null }) => {
    Object.freeze(state);
    switch (type) {
        case GITLAB_GROUPS:
            return gitlabGroups;
        default:
            return state;
    }
};
