import {HAS_AUTHENTICATION} from "../actions/hasAuthentication";

export default (state = null, {type, hasAuthentication = null }) => {
	Object.freeze(state);
	switch (type) {
		case HAS_AUTHENTICATION:
			return hasAuthentication;
		default:
			return state;
	}
};
