import {connect} from "react-redux";
import Avatar from "../../Avatar";
import React from "react";
import DropDown from "../../DropDown";

/*****************************
 * Returns Pagination Table Rows
 *****************************/
  function Row(props) {
    const {deleteUser, reInviteUser, tableFields, type} = props;
	// console.log("TableRow: PERSON:", person)
  // console.log("TableRow: Fields:", tableFields)
  // console.log("Type:", type);
  const renderFields = tableFields.map((field, idx) => {

    if (field === "name"){
      return <td style={{ paddingLeft: "0" }} key={`name-${idx}`}>
      {props[type].firstName} {props[type].lastName}
    </td>
    }

	else if (field === "INVITED") {

		if (props[type].invited){
			return <td key={`createdAt-${idx}`}><span className="pd-0 mg-r-8">Yes</span></td>
		} else {
			return <td key={`createdAt-${idx}`}><span className="pd-0 mg-r-8">No</span></td>
		}

	}
	else if (field === "started") {

		if (props[type].invited){
			if (props[type].started === true) {
				return <td key={`createdAt-${idx}`}><span className="pd-0 mg-r-8">   yes</span></td>
			} else {
				return <td key={`createdAt-${idx}`}><span className="pd-0 mg-r-8">No</span></td>
			}
		} else {
			return <td key={`createdAt-${idx}`}><span className="pd-0 mg-r-8">No </span></td>
		}

	}
    else if(field === "action") {
      console.log('test')
      console.log(props[type])
      return <td key={`action-${idx}`}>
			<DropDown label={"Manage"} icon={"fa-chevron-down"}>
				{
					props[type].invited === true && props[type].started === false ? <li> <button onClick={() => reInviteUser(props[type])}>Resend Invite</button></li> : <></>
				}
				<li> <button onClick={() => deleteUser(props[type].username)} className={'text-danger'}>Delete</button></li>
			</DropDown>
		</td>
    }
    else{
    return <td key={`field-${idx}`}>{props[type][field]}</td>
    }
  });

  return (
    <tr>
      <td>
        <Avatar user={props[type]} size='sm'/>
      </td>
      {renderFields}
    </tr>
  );
}

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Row);
