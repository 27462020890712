import React, {useRef} from 'react'
import {updateUser} from "../../util/settings/updateUser";
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";

function CommPrefs (props) {
    const input = useRef()
    function commPrefsToggle (event) {
        let node = input.current;
        updateUser({
            receiveEmail:node.checked,
            email: props.session.email
        }).then(response => {
            return response.json()
        }).then(data =>{
            props.signin(data.user)
        })
    }

    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Communication Preferences</label>
                        <span className="d-block tx-13 mb-0">How do you want us to communicate with you?</span>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat">
                            <label className="custom-switch">
                                <input ref={input} type="checkbox" onChange={commPrefsToggle} name="custom-switch-checkbox" className="custom-switch-input" defaultChecked={props.session.receiveEmail} />
                                <span className="custom-switch-indicator"></span>
                                <span className="custom-switch-description">Receive notifications through email</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommPrefs);
