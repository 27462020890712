// Action types
export const SET_STUDENT_STATS = 'SET_STUDENT_STATS';

// Action creators
export const setStudentStats = (data) => {
	return {
		type: SET_STUDENT_STATS,
		payload: data,
	}
}
