// Stateless component boilerplate
import React, {useEffect, useState} from "react";

/**
 * Description: Pagination: Segments data into pages that can be cycled through on screen
 *
 * @param {*} type: Indicates the name of the data object used in TableRow to get the data
 * @param {*} data: An array of data that should be shown in the paginated form
 * @param {*} RenderComponent: The TableController component that displays  paginated data,
 * by callingTableRow & TableHead components.
 * @param {*} tableHeaders: The string array of table headers, to be displayed in the table
 * @param {*} tableFields: The string array of data fields to return in each table row,
 *  Note: tableFields should match the order of the tableHeaders
 *
 * Example  - See component/users/User.js
 *
 * @returns Paginated data results
 */

const Pagination = ({
  type,
  data,
  RenderComponent,
  recommendedPageLimit,
  dataLimit, // recordsPerPage
  deleteUser,
  reInviteUser,
  tableHeaders,
  tableFields
}) => {
  const [recordCount, setRecordCount] = useState(data?.length);
  const pages = Math.ceil(recordCount / dataLimit);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPgsDivisibility =
    recommendedPageLimit === 1
      ? pages - recommendedPageLimit
      : pages % recommendedPageLimit;
  const totalPgsInRecLimit = totalPgsDivisibility === 0;

  /* Returns the num of records equal to the dataLimit (10 records),
   ** which will then be displayed to the user.
   */
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    const slicedData = data?.slice(startIndex, endIndex);
   // if searched user is found return only that user record
    if(data && data.length === 1) {
      return data;
    }
    // if results are greater than one return paginated data
    else if(data && data.length > 1){
    return slicedData;
  }
};

  useEffect(() => {
    setRecordCount(data?.length);
    window.scrollTo({ behavior: "smooth", top: "0px" });
  }, [currentPage, pages, data]);

  // Increments the current page by calling setCurrentPage
  const goToNextPage = () => {
    let nextPage = currentPage + recommendedPageLimit;
    const nextPageDivisibility = nextPage % recommendedPageLimit;

    const nextPagePosition =
      nextPageDivisibility === 0 ? recommendedPageLimit : nextPageDivisibility;

    const offset = nextPageDivisibility !== 1 ? nextPagePosition - 1 : 0;

    nextPage += -offset;

    let curPgCanShiftFwd = nextPage <= pages;

    switch (true) {
      case curPgCanShiftFwd:
        setCurrentPage(nextPage);
        break;
      default:
        setCurrentPage((page) => page + 1);
    }
  };

  // Decrements the current page by calling setCurrentPage
  const goToPreviousPage = () => {
    let previousPage = Math.abs(currentPage - recommendedPageLimit);
    const currentPageDivisibility = currentPage % recommendedPageLimit;

    const currentPagePosition =
      currentPageDivisibility === 0
        ? recommendedPageLimit
        : currentPageDivisibility;

    const offset = currentPageDivisibility !== 1 ? currentPagePosition - 1 : 0;

    previousPage += -offset;

    let curPgCanShiftBkwds = previousPage >= 1;

    // Override for EdgeCase currentPage <= recommendedPageLimit
    if (currentPage <= recommendedPageLimit) curPgCanShiftBkwds = false;

    switch (true) {
      case curPgCanShiftBkwds:
        setCurrentPage(previousPage);
        break;
      default:
        setCurrentPage((page) => page - 1);
    }
  };

  // Changes the current page to the page number that was clicked by the user.
  const changePage = (event) => {
    event.preventDefault();
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  };

  // Shows the group of page numbers in the pagination.
  const getPaginationGroup = () => {
    /*************************************************************
     * EdgeCases: pageNumbers size less than recommendedPageLimit
     *************************************************************/

    let isGrpSizeLTRecLimit =
      !totalPgsInRecLimit && recommendedPageLimit < pages;
    let isGrpSizeGTRecLimit =
      !totalPgsInRecLimit && recommendedPageLimit > pages;

    let startOfFinalSet = pages - totalPgsDivisibility + 1;
    let curPgInFinalSet = currentPage >= startOfFinalSet;
    /*********************************************************/

    // Create array to hold page numbers
    const pageNumbers = [];

    let start =
      Math.floor((currentPage - 1) / recommendedPageLimit) *
      recommendedPageLimit;

    // Fill the array with the indexes of the pageNumber items
    for (let idx = 1; idx <= recommendedPageLimit; idx++) {
      let newIdx = start + idx;
      pageNumbers.push(newIdx);
    }

    // Pop pageNumbers array for edgeCase:
    if ((isGrpSizeLTRecLimit || isGrpSizeGTRecLimit) && curPgInFinalSet) {
      let numberOfPops = recommendedPageLimit - totalPgsDivisibility;

      // Pop of extra array:
      for (let idx = 1; idx <= numberOfPops; idx++) {
        pageNumbers.pop();
      }
    }
    return pageNumbers;
  };

  const renderPageNumber = (item, index) => {
    return (
      <li
        key={index}
        onClick={changePage}
        className={`page-item paginationItem ${
          currentPage === item ? "active" : null
        }`}
      >
        <a className="page-link" href="#">
          {item}
        </a>
      </li>
    );
  };

  return (
    <div>
      {/* show the records */}
      {/*{type === "users" ?*/}
      {/*(*/}
      <div className="dataContainer">
        <RenderComponent
          data={getPaginatedData()}
          deleteUser={deleteUser}
          reInviteUser={reInviteUser}
          tableHeaders={tableHeaders}
          tableFields={tableFields}
          type={type}
        />
      </div>
       {/*): (<></>)}*/}
      {recordCount > dataLimit ? (
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center pd-t-25">
            <li
              className={`page-item page-prev ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={goToPreviousPage}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                aria-disabled="true"
              >
                Previous
              </a>
            </li>
            {getPaginationGroup().map((item, index) =>
              renderPageNumber(item, index)
            )}
            <li
              className={`page-item page-next ${
                currentPage >= pages ? "disabled" : ""
              }`}
              onClick={goToNextPage}
            >
              <a className="page-link" href="#">
                Next
              </a>
            </li>
            <li
            className="pag-results"
            >Total Records: {recordCount} </li>
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export default Pagination;
