import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default async function (response) {
	return fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/append`, {
		method: 'POST',
		body: JSON.stringify(response),
		headers: new Headers({
			'Content-Type': 'application/json'
		})
	}).then(newData => {
		return newData.json()
	}).then(data => {
		return data.data
	})
}
