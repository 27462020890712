import AUTH_BASE_URL from "../AUTH_BASE_URL";

const inviteUsers = async (recipients, sender) => {
    // console.log("inviteUsers: emails", recipients);
    const url = "auth/user/invite/"
    const params = {
        recipients,
        sender
    }

    const response = await fetch(AUTH_BASE_URL + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({data: params}),
      });

      // transform results from object to json
      const data = await response.json();

      // call search function
      return data
    };

export default inviteUsers;
