import React, {useState} from 'react';
import {connect} from 'react-redux';
import {allUserData} from '../../../actions/allUsers';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';

const Modal = (props) => {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [uploadView, setUploadView] = useState(false);
	const [file, setFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		if (uploadView) {
			setFile(selectedFile);
		} else {
			setSelectedFile(selectedFile);
		}
	};

	const handleInputChange = (e, setInput) => {
		setInput(e.target.value);
	};

	const handleUpload = () => {
		setUploadView(true);
	};

	const handleBack = () => {
		setUploadView(false);
	};

	const handleSubmit = async (e, buttonType) => {
		e.preventDefault(); // Add this line to prevent form's default behavior
		console.log('submit is clicked');

		if (buttonType === 'add') {
			if (uploadView) {
				if (!file) {
					alert('Please select a file to upload.');
					return;
				}

				const formData = new FormData();
				formData.append('data', JSON.stringify(props.session.data));
				formData.append('file', file);


				try {
					const response = await fetch(
						`${AUTH_BASE_URL}usertesting/classtest/student/addcsv`,
						{
							method: 'POST',
							body: formData,
						}
					);

					if (!response.ok) {
						throw new Error(`HTTP error! status: ${response.status}`);
					}

					const data = await response.json();
					props.allUserData(data);
					props.handleCloseModal(e);
				} catch (error) {
					console.error('There was a problem with the fetch operation: ', error);
				}
			} else {
				if (!email || !firstName || !lastName) {
					alert('All fields must be filled!');
					return;
				}

				const studentData = { email, firstName, lastName, data: props.session.data };

				try {
					console.log('studentData: ', studentData);
					const response = await fetch(
						`${AUTH_BASE_URL}usertesting/classtest/student/add`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(studentData),
						}
					);

					if (!response.ok) {
						console.log('error response: ', response);
						throw new Error(`HTTP error! status: ${response.status}`);
					}

					const data = await response.json();
					data.sort((a, b) => b.createdAt - a.createdAt);
					props.allUserData(data);
					props.handleCloseModal(e);
				} catch (error) {
					console.error('There was a problem with the fetch operation: ', error);
				}
			}
		} else if (buttonType === 'cancel') {
			console.log('Cancel');
		}
	};

	return (
		<div className="main-content pt-0">
			<div className="container-fluid">
				<div className={props.modalClass ? `modal d-block ${props.modalClass}` : 'modal d-block'}
					 style={{ background: 'rgb(0 0 0 / 82%)' }}>
					<div className="modal-dialog" role="dialog">
						<div className="modal-content modal-content-demo shadow pd-t-50">
							<form onSubmit={(e) => handleSubmit(e, 'add')} encType="multipart/form-data">
								{!uploadView ? (
									<div className="detail-modal-body"
										 style={{
											 paddingLeft: '50px',
											 paddingRight: '50px',

										 }}>
										{props.message && <h4>{props.message}</h4>}
										<div className="mg-b-20">
											<label>Students email address</label>
											<input className="form-control" value={email}
												   onChange={(e) => handleInputChange(e, setEmail)} />
										</div>
										<div className="mg-b-20">
											<label>Students first name</label>
											<input className="form-control" value={firstName}
												   onChange={(e) => handleInputChange(e, setFirstName)} />
										</div>
										<div className="mg-b-20">
											<label>Students last name</label>
											<input className="form-control" value={lastName}
												   onChange={(e) => handleInputChange(e, setLastName)} />
										</div>
									</div>
								) : (
									<div className="detail-modal-body"
										 style={{
											 paddingLeft: '50px',
											 paddingRight: '50px',
											 paddingBottom: '50px'
										 }}>
										<h4>Upload a CSV full of students.</h4>
										<p>Please include the [first_name, last_name, email] as headers</p>
										<input type="file" name="file" accept=".csv" onChange={handleFileChange} />
									</div>
								)}
								<div className="modal-footer">
									{!uploadView ? (
										<>
											<button onClick={handleUpload} className="btn ripple btn-secondary" type="button">Upload CSV</button>
											<button onClick={(e) => handleSubmit(e, 'add')} className="btn ripple btn-secondary" type="button">Add Student</button>
											<button onClick={props.handleCloseModal} className="btn ripple btn-primary" type="button">Cancel</button>
										</>
									) : (
										<>
											<button onClick={handleBack} className="btn ripple btn-secondary" type="button">Back</button>
											<button type="submit" className="btn ripple btn-secondary">Add Students</button>
										</>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({
	allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
