import {ALL_PROTOTYPES} from "../actions/allPrototypes";

export default (state = null, {type, prototypes = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_PROTOTYPES:
            return prototypes;
        default:
            return state;
    }
};
