import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const user = async (email) => {
    const url = "auth/user/"
    // console.log(`Delete User Email ${email}`);

        // In delete User
    const response = await fetch(`${AUTH_BASE_URL}${url}${email}`, {
      method: 'DELETE',
    });

    // transform results from object to json
    const data = await response.json();

    // call search function
    return data
  };
