import AUTH_BASE_URL from "../AUTH_BASE_URL";

const ssoSignUp = async (data) => (
  fetch(AUTH_BASE_URL + "auth/sso/user", {
    method: "POST",
    body: JSON.stringify({data}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
);

export default ssoSignUp;