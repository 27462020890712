import {connect} from "react-redux";
import bannerImage from '../../assets/images/pngs/1rt.png'
import Wrapper from '../global/Wrapper.js'
import ProfilePicture from "./ProfilePicture";
import ProfileInfo from './ProfileInfo'
import Services from './Services'
import CommPrefs from "./CommPrefs";
import ThemePrefs from "./ThemePrefs";
import migrateUser from '../../util/users/migrateUser';
import {updateFirstTime} from "../../util/settings/updateFirstTime";
import {signin} from "../../actions/session/signIn";
import {useNavigate} from "react-router-dom";
import ActivityPrefs from "./ActivityPrefs";

function Settings (props) {

   let navigate = useNavigate();



    return (
            <Wrapper noSidebar={props.session.firstTime} noHeader={props.session.firstTime}>
            <div className="inner-body settings">
                <div className="row row-sm">
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                        {/*Row*/}

                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                <ProfilePicture />
                            </div>
                            <div className="col-sm-12  col-md-6 col-lg-6 col-xl-4 pd-10">
                                <ProfileInfo />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                <CommPrefs />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 pd-10">

                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 pd-10">
                                <ThemePrefs />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </Wrapper>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
