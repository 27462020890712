import {HAS_CICD} from "../actions/projectHasCiCd";

export default (state = null, {type, hasCiCd = null }) => {
    Object.freeze(state);
    switch (type) {
        case HAS_CICD:
            return hasCiCd;
        default:
            return state;
    }
};
