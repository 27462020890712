import {GITLAB_GROUP_ID} from "../actions/gitlabGroupId";

export default (state = null, {type, gitlabGroupId = null }) => {
    Object.freeze(state);
    switch (type) {
        case GITLAB_GROUP_ID:
            return gitlabGroupId;
        default:
            return state;
    }
};
