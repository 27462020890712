import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const signin = async (email) => (
  fetch(AUTH_BASE_URL + "auth/sso/session", {
    method: "POST",
    body: JSON.stringify({data:{email}}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
);
