import {USER_TEST} from "../actions/userTest";

export default (state = null, {type, data = {}}) => {
    Object.freeze(state);
    switch (type) {
        case USER_TEST:
            return {data};
        default:
            return state;
    }
};
