import React, {useRef, useState, useEffect} from 'react';
import {connect} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {toastMessage} from "../../actions/toast";

const Toast = props => {

    const toastRef = useRef(null)

    useEffect(()=>{
        return () => {
            handleUnmount()
          };
    },[])

    useEffect(()=> {
		console.log(props)
        console.log('redux toast: ' + props.message)
		if (props.message && props.message.length !== null) {
			setTimeout(event => {
				handleClose()
			},5000)
		}

	},[props.message])

    const handleClose = () => {
        if(toastRef.current){
            toastRef.current.classList.add('fadeOut')
            setTimeout(()=>{
                props.closeToast()
            },300)
        }
    }

    const handleUnmount = () => {
		props.closeToast()
    }

    return(
        <>
		{
            props.message != null ?
             <div className="toast fadein" ref={toastRef}>
                <div>
                    <p>{props.message}</p>
                </div>
                <button className="close" onClick={handleClose}>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
            </div>
            : null
        }
        </>
    )
}


const mapStateToProps = ({toast}) => ({
	toast
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Toast);
