import {resetPassword as resetUtil} from '../../util/session/resetPassword';

export const reset = user => async dispatch => {
  const response = await resetUtil(user);
  // if (response.ok) {
  //   return response
  // } else {
  //   console.log('error')
  // }
  return response
};
