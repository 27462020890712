import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const uploadPhoto = async (data) => {
        return fetch(`${AUTH_BASE_URL}settings/upload/avatar`, {
            method: "POST",
            body: data,
            credentials: 'include',
        })

}
