import React, {useEffect} from "react";
import validateToken from "../../util/dev-ops/validateToken";
import {signin} from "../../actions/session/signIn";
import {connect} from "react-redux";
import {getGitlabGroupData} from "../../actions/gitlabGroups";
import {getGitlabGroupIdData} from "../../actions/gitlabGroupId";
import {updateUser} from "../../util/settings/updateUser";
import getGitlabGroups from "../../util/dev-ops/getGitlabGroups";
import getGitlabSubGroups from "../../util/dev-ops/getGitlabSubGroups";
import {getGitlabSubGroupData} from "../../actions/gitlabSubGroups";
import {getGitlabSubGroupIdData} from "../../actions/gitlabSubGroupId"
import getAllRepos from "../../util/dev-ops/getAllRepos";
import {allGitLabRepos} from "../../actions/allGitlabRepos";

function GroupSelect (props) {

	useEffect(()=> {

	},[props.session])

    useEffect(()=> {
        validateToken(props.session).then(data => {
			props.signin(data.user)
			if (!props.session.gitlabGroup) {
				getGitlabGroups(data).then(groups => {
					// console.log('GROUPS',props.session.team)
					if (props.session.team === 'Creative Technology') {
						// console.log('GROUPS', groups[1].id)
						updateUser({
							email: props.session.email, gitlabGroup: 10566838, gitlabSubGroup: '0', gitlabGroupId: 10566838
						}).then(response => response.json()).then(data => {
							props.signin(data.user)
						})
					} else {
						updateUser({
							email: props.session.email, gitlabGroup: groups[0].id, gitlabSubGroup: '0'
						}).then(response => response.json()).then(data => {
							props.signin(data.user)
						})
					}
				})
			}
            if (props.session.gitlabSubGroup && props.session.gitlabSubGroup !== '0') {
                if (!props.gitlabSubGroup) {
                    props.getGitlabSubGroupIdData(props.session.gitlabSubGroup)
                    getAllRepos(data.token, props.session.gitlabSubGroup).then(response => {
                        props.allGitLabRepos(response)
                    })
                }
            } else {
				// console.log('no subgroup', props.session)
                getAllRepos(data.token, props.session.gitlabGroup).then(response => {
                    props.allGitLabRepos(response)
                })
            }
            getGitlabGroups(data).then(groups => {
                props.getGitlabGroupData(groups)
                return groups
            }).then(groups => {
                if (props.session.gitlabGroup) {
                    if (!props.gitlabGroupId) {
                        props.getGitlabGroupIdData(props.session.gitlabGroup)
                    }
                    return props.session.gitlabGroup
                } else {
                    props.getGitlabGroupIdData(groups[0].id)
                    return groups[0].id
                }
            }).then(id => {
                getGitlabSubGroups(data, id).then(subGroup => {
                    props.getGitlabSubGroupData(subGroup)
                })
            })
        })
    },[props.session.gitlabGroup])



    function handleGroupChange (event) {
        props.getGitlabGroupIdData(event.target.value)
        validateToken(props.session).then(data => {
			props.signin(data.user)
            getGitlabSubGroups(data, event.target.value).then(subGroup => {
                props.getGitlabSubGroupData(subGroup)
                if (subGroup[0]) {
                    props.getGitlabSubGroupIdData(subGroup[0].id)
                } else {
                    props.getGitlabSubGroupIdData(0)
                }

            })
        })
        updateUser({
            email: props.session.email, gitlabGroup: event.target.value
        }).then(response => response.json()).then(data => {
            props.signin(data.user)
        })
    }

    return (
        <select style={{display: 'inline-flex', height: '40px'}} onChange={handleGroupChange} name="group" className="form-control select mg-r-8" required="" value={props.session.gitlabGroup} >
            {
                props.gitlabGroups ? props.gitlabGroups.map((group,index) => {
                    return (
                        <option key={index} data-index={index} value={group.id} >{group.name}</option>
                    )
                }) : <></>
            }
        </select>
    )
}
const mapStateToProps = ({session, gitlabGroups, gitlabGroupId, gitlabSubGroups}) => ({
    session, gitlabGroups, gitlabGroupId, gitlabSubGroups
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
    getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
    getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
    getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
    getGitlabSubGroupIdData: subgroups => dispatch(getGitlabSubGroupIdData(subgroups)),
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSelect);
